import i18n from '@/boot/i18n'
import store from '@/boot/store'
import { title } from '@/utilities/filters'
import {
  isInteger,
  isFloat,
  isGreaterThanOrEqualToN,
  isLessThanOrEqualToN,
  isRequired,
  isRoundedToNDecimalPlaces,
  isGreaterThanN,
} from '@/utilities/validations'

export const FIELDS = [
  'shippingServiceCode',
  'description',
  'originRegion',
  'destinationRegion',
  'destinationZone',
  'containerId',
  'weight',
  'price',
  'additionalPricePerKilo',
  'surcharge',
]

export const FIELDS_DEFAULT = [
  'shippingServiceCode',
  'description',
  'originRegion',
  'destinationRegion',
  'destinationZone',
  'containerId',
  'weight',
  'price',
  'additionalPricePerKilo',
  'surcharge',
  'currency',
]

export const shippingServiceCodeField = () => {
  return {
    name: 'shippingServiceCode',
    label: title(i18n.t('billing.shippingService')),
    type: 'text',
    component: 'v-autocomplete',
    items: store.getters['billing/getShippingServices']?.data?.filter(s => !s.hidden),
    itemText: 'name',
    itemValue: 'code',
    rules: [isRequired],
  }
}

export const originRegionField = () => {
  return {
    name: 'originRegion',
    component: 'v-select',
    label: title(i18n.t('billing.originRegion')),
    items: store.getters['billing/getRegions']?.data?.filter(r => r.origin) || [],
    ...regionField(),
  }
}

export const destinationRegionField = () => {
  return {
    name: 'destinationRegion',
    component: 'v-autocomplete',
    label: title(i18n.t('billing.destinationRegion')),
    items: store.getters['billing/getRegions']?.data || [],
    ...regionField(),
  }
}

export const destinationZoneField = () => {
  return {
    name: 'destinationZone',
    component: 'v-autocomplete',
    label: title(i18n.t('billing.destinationZone')),
    formHelperText: 'Add the destination zone or set as DEFAULT.',
    items: store.getters['billing/getZones']?.data || [],
    ...zoneField(),
    rules: [isRequired],
  }
}

export const descriptionField = () => {
  return {
    name: 'description',
    label: title(i18n.t('common.description')),
    formHelperText: 'The description will appear on a clients invoice.',
    ...textField(),
  }
}

export const containerTypeField = () => {
  return {
    name: 'containerId',
    label: title(i18n.t('billing.containerType')),
    component: 'v-select',
    items: store.getters['billing/getContainerTypeCodes'] || [],
    itemText: 'description',
    itemValue: 'id',
    rules: [isRequired],
  }
}

export const weightField = () => {
  return {
    name: 'weight',
    label: title(i18n.t('common.weight')) + ' (g)',
    type: 'number',
    min: 0,
    placeholder: '0',
    rules: [v => isInteger(v), v => isGreaterThanN(v, 0)],
  }
}

export const priceField = () => {
  return {
    name: 'price',
    label: title(i18n.t('common.price')),
    ...decimalField(),
  }
}

// Default pricing.
export const currencyField = () => {
  return {
    name: 'currency',
    label: title(i18n.t('common.currency')),
    ...textField(),
  }
}

export const additionalPricePerKiloField = () => {
  return {
    name: 'additionalPricePerKilo',
    label: title(i18n.t('billing.additionalPricePerKilo')),
    ...decimalField(),
  }
}

export const surchargeField = () => {
  return {
    name: 'surcharge',
    label: title(i18n.t('common.surcharge')) + ' (%)',
    ...decimalField(),
  }
}

const decimalField = () => {
  return {
    min: 0,
    max: 1000000,
    type: 'number',
    placeholder: '0.00',
    step: 0.01,
    rules: [
      v => isFloat(v),
      v => isGreaterThanOrEqualToN(v, 0),
      v => isLessThanOrEqualToN(v, 1000000),
      v => isRoundedToNDecimalPlaces(v, 2),
    ],
  }
}

const textField = () => {
  return {
    type: 'text',
    rules: [isRequired],
  }
}

const regionField = () => {
  return {
    itemText: 'displayName',
    itemValue: 'code',
    ...textField(),
  }
}

const zoneField = () => {
  return {
    itemText: 'description',
    itemValue: 'code',
    ...textField(),
    rules: [isRequired],
  }
}

export const getRegion = code => {
  return store.getters['billing/getRegions']?.data?.find(r => r.code === code)
}

export const getShippingService = code => {
  return store.getters['billing/getShippingServices']?.data?.find(s => s.code === code)
}

export const getContainerTypeCode = id => {
  return store.getters['billing/getContainerTypeCodes']?.find(c => c.id === id)
}

export const getZone = code => {
  return store.getters['billing/getZones']?.data?.find(r => r.code === code)
}
