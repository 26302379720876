<template>
  <v-alert :type="type" text outlined>
    {{ text }}
  </v-alert>
</template>

<script>
export default {
  name: 'alert',

  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['info', 'success', 'warning', 'error'].includes(value)
      },
    },
    text: {
      type: String,
      required: true,
    },
  },
}
</script>
